
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
@import '~bootstrap/dist/css/bootstrap.min.css';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$shivamerp-primary: mat-palette($mat-indigo);
$shivamerp-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$shivamerp-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$shivamerp-theme: mat-light-theme($shivamerp-primary, $shivamerp-accent, $shivamerp-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($shivamerp-theme);

/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import '~ngx-toastr/toastr.css';

/*.ag-header-cell .ag-header-cell-text{
    
}*/
.gridHeading{
    color:#b9350d;
    font-weight:bold;
    justify-content: center;
}

.txtCt{
    text-align: center;
}

.valignM{
    vertical-align: middle;
}

.mat-button-toggle-checked{
    background-color: #ffffff;
}

.mat-button-toggle-label-content{
    color:#b1330d
}

.mat-button-toggle-checked .mat-button-toggle-label-content{
    color:#0bb55b
}

/* mat-confirm-dialog (customised dialog) */
.confirm-dialog-container .mat-dialog-container {
    border-radius: .25em .25em .4em .4em;
    padding: 0px;
}
.confirm-dialog-container .content-container{
    margin: 5px 5px 15px 5px;
    color: #3e3e3e;
    display: flex;
}
.confirm-dialog-container #close-icon{
    margin-left: auto;
    order: 2;
    font-weight: bolder;
}
.confirm-dialog-container #close-icon:hover{
    cursor: pointer;
}

.confirm-dialog-container #no-button{
    height: 50px;
    width: 50%;
    background-color: #b1330d;
    color:white;
    border-radius: 0px;
}

.confirm-dialog-container #yes-button{
    height: 50px;
    width: 50%;
    background-color: #0bb55b;
    color:white;
    border-radius: 0px;
}

.confirm-dialog-container span.content-span{
    padding:  35px 16px;
    text-align: center;
    font-size: 20px;
}

.mat-tree{
    background: #fafafa;
}

.curPointer:hover{
    cursor: pointer;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }